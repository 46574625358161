<template>
    <div class="sup_content language-list">
        <div class="sup_page_heading">
            <h2>Website Content</h2>
            <Button icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add New Page'" @click="pageModals.addPage = true" />
        </div>
        <div class="sup_inner_content">
            <!--- Show Language list Start --->
            <DataTable class="p-datatable" :paginator="true" :value="allPageData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allPageData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading Pages data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button :label="allPageData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allPageData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allPageData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllPages(allPageData.activityStatus, 'languageStatus')" />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allPageData.search" placeholder="Search Page" @keyup.enter="fetchAllPages(allPageData.activityStatus, 'search')"/>
                            </span>
                            <Calendar v-model="allPageData.dateRange" placeholder="DD.MM.YY" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" :showIcon="true" @date-select="allPageData.dateRange[1] != null ? fetchAllPages(allPageData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allPageData.search !== null || allPageData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllPages(allPageData.activityStatus, 'resetFilters')"/>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Pages found...
                </template>
                <Column field="type" header="Page Name" :sortable="true">
                </Column>

                <Column field="title" header="Title" :sortable="true">
                </Column>

                <Column field="createdAt" header="Created On">
                    <template #body="slotProps">
                        {{usFormatDate(slotProps.data.createdAt)}}
                    </template>
                </Column>

                <Column field="status" header="Status" headerStyle="8rem">
                    <template #body="slotProps">
                        <span :class="['sup_item_status ', slotProps.data.status ? 'sup_item_status_success' : 'sup_item_status_danger']"><i :class="['pi ', slotProps.data.status ? 'pi-check' : 'pi-times']"></i>{{slotProps.data.status ? 'Active' : 'In-Active'}}</span>
                    </template>
                </Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-primary p-mr-1" @click="editPage(slotProps.data)" v-tooltip="'Edit Page'" />
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="confirmationModalfn(slotProps.data, 'delete')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore Page'" @click="confirmationModalfn(slotProps.data, 'restore')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-trash" class="p-button-danger p-button-rounded p-button-sm"  v-tooltip="'Delete Page'" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Language list End --->
        </div>
        <!--- Add Language Modal Start --->
        <Dialog header="Add New Page" v-model:visible="pageModals.addPage" :modal="true" :style="{width: '600px'}">
            <form class="sup_language_add" @submit.prevent="createPage()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="l_name">Page Type</label>
                        <Dropdown v-model="addEditPage.type" :options="addEditDropdownData.pageType" :filter="true" id="l_name" optionLabel="masterType" optionValue="masterType" placeholder="Select Page Type" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.typeEmpty">Select a page name.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="l_lCode">Language</label>
                        <Dropdown v-model="addEditPage.languageCode" :options="addEditDropdownData.languageType" :filter="true" id="l_lCode" optionLabel="languageName" optionValue="languageCode" placeholder="Select Language" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.languageEmpty">Select a language code.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="l_title">Title</label>
                        <InputText v-model="addEditPage.title" id="l_title" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.titleEmpty">Title is Required(Max: 100 > Min : 1).</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="p_desc">Page Description</label>
                        <Editor v-model="addEditPage.description" id="p_desc" editorStyle="height: 200px"/>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.detailsEmpty">Page description is Required.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Add" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', addEditPage.addStatus === 200 ? 'success' : 'error']" v-if="addEditPage.addStatus !== ''">{{addEditPage.addMessage}}</div >
            </form>
        </Dialog>
        <!--- Add Language Modal End --->
        <!--- Edit Language Modal Start --->
        <Dialog header="Edit Page Details" v-model:visible="pageModals.editPage" :modal="true" :style="{width: '600px'}" @hide="resetForm()">
            <form class="sup_language_edit" @submit.prevent="updatePage(pageModals.editData)">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="l_name">Page Type</label>
                        <Dropdown v-model="addEditPage.type" :options="addEditDropdownData.pageType" :filter="true" id="l_name" optionLabel="masterType" optionValue="masterType" placeholder="Select Page Type" disabled/>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.typeEmpty">Select a page name.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="l_lCode">Language</label>
                        <Dropdown v-model="addEditPage.languageCode" :options="addEditDropdownData.languageType" :filter="true" id="l_lCode" optionLabel="languageName" optionValue="languageCode" placeholder="Select Language" disabled />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.languageEmpty">Select a language code.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="l_title">Title</label>
                        <InputText v-model="addEditPage.title" id="l_title" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.titleEmpty">Title is Required(Max: 100 > Min : 1).</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="p_desc">Page Description</label>
                        <Editor v-model="addEditPage.description" id="p_desc" editorStyle="height: 200px"/>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.detailsEmpty">Page description is Required.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Update" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', addEditPage.editStatus === 200 ? 'success' : 'error']" v-if="addEditPage.editStatus !== ''">{{addEditPage.editMessage}}</div >
            </form>
        </Dialog>
        <!--- Edit Language Modal End --->
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deleteAndRestoreLanguage(confirmationModal.modaldata.data, confirmationModal.modaldata.type), confirmationModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->
    </div>
</template>

<script>
import { onBeforeMount, reactive } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { allPageList, pageById, allLanguageList, allPageTypeList } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import { usFormatDate, capitalizeCase, formatDate } from "../../../../helper/utility/utility";

export default {
    name: "pages",
    setup(){
        //Store call
        let store = useStore();

        //Fetch and filtring data initial state
        const allPageData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'active',
            search: null,
            dateRange: null
        });

        //Page modals open-close state 
        const pageModals = reactive ({
            addPage: false,
            editPage: false,
            editData: null
        });

        //All from input and message data binding state
        const addEditPage = reactive ({
            type: '',
            title: '',
            description : '',
            languageCode : '',
            addStatus: '',
            addMessage: '',
            editStatus: '',
            editMessage: ''
        });

        //All from error handling state
        const addEditvalidationError = reactive ({
            titleEmpty: false,
            typeEmpty: false,
            languageEmpty: false,
            detailsEmpty : false
        });

        //Add-edit form dropdown list data initial state
        const addEditDropdownData = reactive({
            pageType: null,
            languageType : null
        });

        //Active-deactive confimation state
        const confirmationModal = reactive({
            modalState: false,
            modaldata: '',
        });

        //Before component mount required function invoke
        onBeforeMount(async () => {
            fetchAllPages();
            fetchPageType();
            fetchAllLanguages();
        });

        //All Page data fetching
        const fetchAllPages = async (status, activity) => {
            let date = null;
            if(activity === 'languageStatus'){
                allPageData.activityStatus = status === 'active' ? 'de-active' : 'active' ;
            }

            if(activity === 'resetFilters'){
                allPageData.search = null;
                allPageData.dateRange = null;
            }

            if (activity === 'dateRange') {
                date = formatDate(allPageData.dateRange[0]) + ' ' + formatDate(allPageData.dateRange[1]);
            }

            try{
                allPageData.loading = !allPageData.loading;
                const response = await axios.get(allPageList, {
                    params: {
                        status: allPageData.activityStatus,
                        search: allPageData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                })
                allPageData.data = response.data.data.template;
                allPageData.total = response.data.data.total;
                allPageData.loading = !allPageData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        //Page code data list fetching
        const fetchPageType = async() => {
            try{

                const response = await axios.get(allPageTypeList, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                addEditDropdownData.pageType = response.data.data.pageType;
                
            } catch(err){
                addEditDropdownData.pageType = null;
            }
        }

        //Page code data list fetching
        const fetchAllLanguages = async() => {
            try{
                const response = await axios.get(allLanguageList, {
                    params: {
                        status: 'all'
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                })
                addEditDropdownData.languageType = response.data.data.language;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        //New Page create HTTP call
        const createPage = async() => {
            let isValidate = pageFormValidation();

            if(isValidate){
                try{
                    const response = await axios.post(allPageList, {
                        type: addEditPage.type,
                        title: addEditPage.title,
                        details: addEditPage.description,
                        languageCode: addEditPage.languageCode
                    },{
                        headers:  {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }
                    });
                    addEditPage.addStatus = response.data.status;
                    addEditPage.addMessage = response.data.message;
                    resetForm();
                    fetchAllPages();
                } catch(err){
                    addEditPage.addStatus = err.response.data.status;
                    addEditPage.addMessage = err.response.data.message;
                }
            }
        }

        //Add-edit Page form validation
        const pageFormValidation = () => {
            if(addEditPage.title === "" || addEditPage.title.length > 100){
                addEditvalidationError.titleEmpty = true;
                document.getElementById("l_title").focus()
            } else {
                addEditvalidationError.titleEmpty = false;
            }

            if(addEditPage.type === ""){
                addEditvalidationError.typeEmpty = true;
                document.getElementById("l_name").focus()
            } else {
                addEditvalidationError.typeEmpty = false;
            }

            if(addEditPage.languageCode === ""){
                addEditvalidationError.languageEmpty = true;
                document.getElementById("l_lCode").focus()
            } else {
                addEditvalidationError.languageEmpty = false;
            }

            if(addEditPage.description === ""){
                addEditvalidationError.detailsEmpty = true;
                document.getElementById("p_desc").focus()
            } else {
                addEditvalidationError.detailsEmpty = false;
            }

            if(addEditvalidationError.titleEmpty && addEditvalidationError.typeEmpty && addEditvalidationError.languageEmpty && addEditvalidationError.detailsEmpty) {
                return false;
            } else {
                return true;
            }
        }

        //Reset form
        const resetForm = () => {
            addEditPage.type = '';
            addEditPage.title = '';
            addEditPage.languageCode = '';
            addEditPage.description = '';
            addEditPage.addStatus= '';
            addEditPage.addMessage= '';
            addEditPage.editStatus= '';
            addEditPage.editMessage= '';
        }

        //Delete-restore Page confirmation modal
        const confirmationModalfn = (sloteData, load) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData,
                type: load
            }
        }

        //Delete-restore Page HTTP call
        const deleteAndRestoreLanguage = async (slotData, methodType) => {
            try{
                const options = {
                    method: methodType === 'delete' ? 'delete' : 'patch',
                    url: allPageList,
                    params: {
                        uuid: slotData.uuid
                    },
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                fetchAllPages('active');
            } catch(err){
                console.log(err);
            }
        }

        //Specific Page data fill up and edit popup one
        const editPage = async(slotData) => {
            const response = await axios.get( pageById, {
                params: {
                    uuid: slotData.uuid,
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if(response.data.status === 200 ){
                pageModals.editPage = true;
                pageModals.editData = slotData.uuid
                addEditPage.type = response.data.data.type;
                addEditPage.title = response.data.data.title;
                addEditPage.languageCode = response.data.data.languageCode;
                addEditPage.description = response.data.data.details;
            }
        }

        //Page update HTTP call
        const updatePage = async(uuid) => {
            let isValidate = pageFormValidation();

            if(isValidate){
                try{
                    const options = {
                        method: 'put',
                        url: allPageList,
                        params: {
                            uuid: uuid
                        },
                        data: JSON.stringify({
                            type: addEditPage.type,
                            title: addEditPage.title,
                            details: addEditPage.description,
                        }),
                        headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                        transformRequest: [(data) => {
                            // transform the data
                            return data;
                        }]
                    };
                    let response = await axios(options);
                    addEditPage.editStatus = response.data.status;
                    addEditPage.editMessage = response.data.message;
                    fetchAllPages('active');
                } catch(err){
                    addEditPage.editStatus = err.response.data.status;
                    addEditPage.editMessage = err.response.data.message;
                }
            }
        }

        return {
            allPageData,
            addEditPage,
            pageModals,
            addEditvalidationError,
            addEditDropdownData,
            confirmationModal,
            fetchAllPages,
            usFormatDate,
            createPage,
            capitalizeCase,
            confirmationModalfn,
            deleteAndRestoreLanguage,
            editPage,
            updatePage,
            resetForm
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/component/superAdmin/pages/widgetManagement/Languages'
</style>